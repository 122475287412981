<template>
  <div class="card">
    <div class="card-header">
      <ul class="nav nav-tabs" id="myTab" role="tablist">
        <li class="nav-item">
          <a
            class="nav-link"
            id="tab-Ve"
            data-toggle="tab"
            href="#Ve_inAc"
            v-show="Object.keys($parent.vehiculosInAc).length > 0"
            ><b>Inactivos</b></a
          >
        </li>
        <li class="nav-item">
          <a
            class="nav-link"
            id="tab-Ve"
            data-toggle="tab"
            href="#Ve"
            v-show="Object.keys($parent.vehiculos).length > 0"
            ><b>Internos</b></a
          >
        </li>
        <li class="nav-item">
          <a
            class="nav-link"
            id="tab-Renting"
            data-toggle="tab"
            href="#Renting"
            v-show="Object.keys($parent.vehiculosRen).length > 0"
            ><b>Renting</b></a
          >
        </li>
        <li class="nav-item">
          <a
            class="nav-link"
            id="tab-Nacionales"
            data-toggle="tab"
            href="#Nacionales"
            v-show="Object.keys($parent.vehiculosNa).length > 0"
            ><b>Nacionales</b></a
          >
        </li>
      </ul>
      <div class="tab-content" id="myTabContent">
        <!--- Vehículo Inactivos ---->
        <div
          class="tab-pane fade"
          id="Ve_inAc"
          v-show="Object.keys($parent.vehiculosInAc).length > 0"
        >
          <div class="card-body table-responsive">
            <div class="row">
              <div class="col-md-12">
                <table
                  id="cars"
                  class="
                    table table-bordered table-striped table-hover table-sm
                  "
                >
                  <thead>
                    <tr>
                      <th>
                        Vehiculo
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          v-model="filtros.vehiculo_fil_in"
                          @keyup.enter="$parent.getIndex()"
                        />
                      </th>
                      <th>
                        Empresa
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          v-model="filtros.empresa_nombre_in"
                          @keyup.enter="$parent.getIndex()"
                        />
                      </th>
                      <th>Fecha de su ultima posicion</th>
                      <th>Latitud</th>
                      <th>Longitud</th>
                      <th>Dirección</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="item in $parent.vehiculosInAc.data"
                      :key="item.id"
                    >
                      <td>
                        {{ item.placa }}
                      </td>
                      <td>
                        {{ item.empresa.razon_social }}
                      </td>

                      <td v-if="item.ultimo_registro">
                        {{ item.ultimo_registro.fecha_hora_ultimo_reporte }}
                      </td>
                      <td v-else></td>
                      <td v-if="item.ultimo_registro">
                        {{ item.ultimo_registro.gps.dblLatitud }}
                      </td>
                      <td v-else></td>
                      <td v-if="item.ultimo_registro">
                        {{ item.ultimo_registro.gps.dblLongitud }}
                      </td>
                      <td v-else></td>
                      <td v-if="item.ultimo_registro">
                        {{ item.ultimo_registro.gps.strDireccion }}
                      </td>
                      <td v-else></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="card-footer">
            <div class="float-left" v-if="$parent.vehiculosInAc.total">
              <p>
                Mostrando del <b>{{ $parent.vehiculosInAc.from }}</b> al
                <b>{{ $parent.vehiculosInAc.to }}</b> de un total:
                <b>{{ $parent.vehiculosInAc.total }}</b> Registros
              </p>
            </div>
            <div class="float-left" v-else>
              <p>
                <span class="badge badge-danger">
                  No hay registros para mostrar
                </span>
              </p>
            </div>
            <pagination
              :data="$parent.vehiculosInAc"
              @pagination-change-page="$parent.getIndex"
              :limit="10"
              align="right"
            ></pagination>
          </div>
        </div>
        <!---- Vehículo Internos ----->
        <div
          class="tab-pane fade"
          id="Ve"
          v-show="Object.keys($parent.vehiculos).length > 0"
        >
          <div class="card-body table-responsive">
            <div class="row">
              <div class="col-md-12">
                <table
                  id="cars"
                  class="
                    table table-bordered table-striped table-hover table-sm
                  "
                >
                  <thead>
                    <tr>
                      <th>
                        Vehiculo
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          v-model="filtros.vehiculo_fil"
                          @keyup.enter="$parent.getIndex()"
                        />
                      </th>
                      <th>
                        Empresa
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          v-model="filtros.empresa_nombre"
                          @keyup.enter="$parent.getIndex()"
                        />
                      </th>
                      <th>
                        Conductor
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          v-model="filtros.conductor_nombre"
                          @keyup.enter="$parent.getIndex()"
                        />
                      </th>
                      <th>
                        Sitio de la Solicitud
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          v-model="filtros.sitio_s"
                          @keyup.enter="$parent.getIndex()"
                        />
                      </th>
                      <th>Fecha ultima posicion</th>
                      <th>Latitud</th>
                      <th>Longitud</th>
                      <th>Dirección</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in $parent.vehiculos.data" :key="item.id">
                      <td>
                        {{ item.placa }}
                      </td>
                      <td>
                        {{ item.empresa.razon_social }}
                      </td>
                      <td>
                        {{ item.tep_turno.conductor.apellidos }}
                        {{ item.tep_turno.conductor.nombres }}
                      </td>
                      <td>
                        {{ item.tep_turno.sitio.nombre }}
                      </td>
                      <td v-if="item.ultimo_registro">
                        {{ item.ultimo_registro.fecha_hora_ultimo_reporte }}
                      </td>
                      <td v-else></td>
                      <td v-if="item.ultimo_registro">
                        {{ item.ultimo_registro.gps.dblLatitud }}
                      </td>
                      <td v-else></td>
                      <td v-if="item.ultimo_registro">
                        {{ item.ultimo_registro.gps.dblLongitud }}
                      </td>
                      <td v-else></td>
                      <td v-if="item.ultimo_registro">
                        {{ item.ultimo_registro.gps.strDireccion }}
                      </td>
                      <td v-else></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="card-footer">
            <div class="float-left" v-if="$parent.vehiculos.total">
              <p>
                Mostrando del <b>{{ $parent.vehiculos.from }}</b> al
                <b>{{ $parent.vehiculos.to }}</b> de un total:
                <b>{{ $parent.vehiculos.total }}</b> Registros
              </p>
            </div>
            <div class="float-left" v-else>
              <p>
                <span class="badge badge-danger">
                  No hay registros para mostrar
                </span>
              </p>
            </div>
            <pagination
              :data="$parent.vehiculos"
              @pagination-change-page="$parent.getIndex"
              :limit="10"
              align="right"
            ></pagination>
          </div>
        </div>

        <!--- Vehículo Nacionales ---->
        <div
          class="tab-pane fade"
          id="Nacionales"
          v-show="Object.keys($parent.vehiculosNa).length > 0"
        >
          <div class="card-body table-responsive">
            <div class="row">
              <div class="col-md-12">
                <table
                  id="cars"
                  class="
                    table table-bordered table-striped table-hover table-sm
                  "
                >
                  <thead>
                    <tr>
                      <th>
                        Vehiculo
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          v-model="filtros.vehiculo_filN"
                          @keyup.enter="$parent.showCarros()"
                        />
                      </th>
                      <th>
                        Empresa
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          v-model="filtros.empresa_nombreN"
                          @keyup.enter="$parent.getIndex()"
                        />
                      </th>
                      <th>
                        Conductor
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          v-model="filtros.conductor_nombreN"
                          @keyup.enter="$parent.getIndex()"
                        />
                      </th>
                      <th>
                        Sitio de la Solicitud
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          v-model="filtros.sitio_sN"
                          @keyup.enter="$parent.getIndex()"
                        />
                      </th>
                      <th>Fecha de Solicitud</th>
                      <th>Latitud</th>
                      <th>Longitud</th>
                      <th>Dirección</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in $parent.vehiculosNa.data" :key="item.id">
                      <td>
                        {{ item.placa }}
                      </td>
                      <td>
                        {{ item.empresa.razon_social }}
                      </td>
                      <td v-if="item.tep_viaje.conductor">
                        {{ item.tep_viaje.conductor.apellidos }}
                        {{ item.tep_viaje.conductor.nombres }}
                      </td>
                      <td v-else></td>
                      <td v-if="item.tep_solicitud">
                        {{
                          item.tep_solicitud.sitio
                            ? item.tep_solicitud.sitio.nombre
                            : ""
                        }}
                      </td>
                      <td v-else></td>
                      <td v-if="item.tep_solicitud">
                        {{ item.tep_solicitud.fecha_hora_fin_servicio }}
                      </td>
                      <td v-else></td>
                      <td v-if="item.ultimo_registro">
                        {{ item.ultimo_registro.gps.dblLatitud }}
                      </td>
                      <td v-else></td>
                      <td v-if="item.ultimo_registro">
                        {{ item.ultimo_registro.gps.dblLongitud }}
                      </td>
                      <td v-else></td>
                      <td v-if="item.ultimo_registro">
                        {{ item.ultimo_registro.gps.strDireccion }}
                      </td>
                      <td v-else></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="card-footer">
            <div class="float-left" v-if="$parent.vehiculosNa.total">
              <p>
                Mostrando del <b>{{ $parent.vehiculosNa.from }}</b> al
                <b>{{ $parent.vehiculosNa.to }}</b> de un total:
                <b>{{ $parent.vehiculosNa.total }}</b> Registros
              </p>
            </div>
            <div class="float-left" v-else>
              <p>
                <span class="badge badge-danger">
                  No hay registros para mostrar
                </span>
              </p>
            </div>
            <pagination
              :data="$parent.vehiculosNa"
              @pagination-change-page="$parent.getIndex"
              :limit="10"
              align="right"
            ></pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import pagination from "laravel-vue-pagination";

export default {
  name: "TepMapasListVehiculos",
  components: {
    pagination,
  },
  data() {
    return {
      filtros: {},
    };
  },

  methods: {},
};
</script>
